import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";

export const Copyright = () => {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://devinsights.app/">
        devinsights.app
      </Link>{" "}
      {new Date().getFullYear()}
    </Typography>
  );
};
