import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { Amplify } from "aws-amplify";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { TenantProvider } from "./contexts/TenantContext";

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID || "",
      userPoolClientId:
        process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID || "",
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <Authenticator.Provider>
      <TenantProvider>
        <BrowserRouter>
          <App isPassedToWithAuthenticator />
        </BrowserRouter>
      </TenantProvider>
    </Authenticator.Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
