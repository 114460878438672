import { useAuthenticator } from "@aws-amplify/ui-react";
import Button from "@mui/material/Button";
import React from "react";
import { useNavigate } from "react-router";

const LogoutButton = () => {
  const { signOut } = useAuthenticator((context) => [context.user]);
  const navigate = useNavigate();

  function logOut() {
    signOut();
    navigate("/");
  }

  return (
    <Button variant="contained" onClick={async () => logOut()}>
      Log Out
    </Button>
  );
};

export default LogoutButton;
