import { Backdrop, CircularProgress, Container } from "@mui/material";
import React from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import Dashboard from "./pages/Dashboard";
import {
  withAuthenticator,
  WithAuthenticatorProps,
} from "@aws-amplify/ui-react";
import Integrations from "./pages/Integrations";
import OauthGitHub from "./pages/OauthGitHub";
import { useTenant } from "./contexts/TenantContext";

interface Props extends WithAuthenticatorProps {
  isPassedToWithAuthenticator: boolean;
}

function App({ isPassedToWithAuthenticator }: Props) {
  if (!isPassedToWithAuthenticator) {
    throw new Error(`isPassedToWithAuthenticator was not provided`);
  }

  const {tenantName} = useTenant()

  if(tenantName) {
    return (
      <Container className="app">
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/integrations" element={<Integrations />} />
          <Route path="/oauth/github" element={<OauthGitHub />} />
        </Routes>
      </Container>
    );
  }
    return <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={!!tenantName} >
        <CircularProgress color="inherit" />
      </Backdrop>;
}

export default withAuthenticator(App, { hideSignUp: true });

export async function getStaticProps() {
  return {
    props: {
      isPassedToWithAuthenticator: true,
    },
  };
}
