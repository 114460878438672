import { Backdrop, Button, CircularProgress, Grid, Typography } from "@mui/material";
import { fetchAuthSession } from "aws-amplify/auth";
import { Link as RouterLink } from "react-router-dom";
import { deleteIntegration, getIntegrations } from "./../services/api";
import React, { Fragment, useEffect, useState } from "react";
import PrivateLayout from "../layouts/private/Layout";
import "../App.css";
import { GetIntegrationsApiResponse } from "../types/integrationsApiResponse";

function Integrations() {
  const [tenantId, setTenantId] = useState<string | undefined>(undefined);
  const [integrations, setIntegrations] = useState<GetIntegrationsApiResponse[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    async function getData() {
      setLoading(true);
      const session = await fetchAuthSession();
      setTenantId(
        session.tokens?.idToken?.payload["custom:tenantId"] as string
      );
      const integtations = await getIntegrations();
      setIntegrations(integtations || []);
      setLoading(false);
    }
    getData();
  }, []);

  const getGitHubStatus = () => {
    return integrations.find(i => i.integration === "github")?.status;
  }

  const handleDeleteClicked = async () => {
    await deleteIntegration(getGithubData().installationId);
    const integtations = await getIntegrations();
    setIntegrations(integtations || []);
    setLoading(false);
  }

  const getGithubData = () => {
    const githubIntegration = integrations.find(i => i.integration === "github");
    return ({
      lastModifiedAt: githubIntegration ? githubIntegration.lastModifiedAt : "",
      lastModifiedBy: githubIntegration ? githubIntegration.lastModifiedBy : "",
      installationId: githubIntegration ? githubIntegration.installationId : ""
    })
  }

  const showLoading = () => {
    return <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={loading} >
            <CircularProgress color="inherit" />
          </Backdrop>;
  }
  
  const showContent = () => {
    return <Grid container spacing={3} flexDirection="column">
        <Typography variant="h4" paddingBottom={3} >
          GitHub
        </Typography>
        {
          getGitHubStatus() === "connected" ? (
            <Fragment>
              <Typography variant="body1" paragraph>
                You installed the DevInsights GitHub App. You can deinstall the app by just clicking on the button below.
              </Typography>
              <Grid columns={1} paddingBottom={2}>
                <Button
                  variant="contained"
                  onClick={handleDeleteClicked}
                  >
                  Disonnect GitHub
                </Button>
              </Grid>
              <Grid>
                <Typography variant="body2" paragraph >Last Modified by <b>{getGithubData().lastModifiedBy}</b> at {getGithubData().lastModifiedAt}</Typography >
              </Grid>
            </Fragment>
          ) : (
            <Fragment>              
              <Typography variant="body1" paragraph>
                DevInsights uses GitHub to authenticate users and access repositories.
                Click the button below to authenticate with GitHub. You need to be the
                <b> owner</b> of the repository to add it to DevInsights.
              </Typography>
              <Grid columns={1} paddingBottom={2}>
                <Button
                  component={RouterLink}
                  variant="contained"
                  to={`https://github.com/apps/devinsights-app/installations/new?state=${tenantId}`}
                  >
                    Connect GitHub
                </Button>
              </Grid>
            </Fragment>
          )
        }
      </Grid>
    }

  return (
    <PrivateLayout>
      { loading ? showLoading() : showContent() }
    </PrivateLayout>
  );
}

export default Integrations;
