// ui-application/src/context/TenantContext.tsx

import React, { createContext, useContext, useEffect, useState } from 'react';
import { getTenantName } from '../services/api';
import { Hub } from 'aws-amplify/utils';

interface TenantContextType {
  tenantName: string;
}

const TenantContext = createContext<TenantContextType | undefined>(undefined);

export const TenantProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [tenantName, setTenantName] = useState<string>('');
  
  const fetchTenantName = async () => {
    try {
      const name = await getTenantName();
      setTenantName(name);
      localStorage.setItem('tenantName', name);
    } catch (error) {
      console.error('Failed to fetch tenant name:', error);
      setTenantName('Unknown Tenant');
    }
  };

  useEffect(() => {
    fetchTenantName();

    const hubListener = Hub.listen('auth', (data) => {
      switch (data.payload.event) {
        case 'signedIn':
          fetchTenantName();
          break;
        case 'signedOut':
          setTenantName('');
          break;
      }
    });

    return () => {
      hubListener()
    };
  }, []);

  return (
    <TenantContext.Provider value={{ tenantName }}>
      {children}
    </TenantContext.Provider>
  );
};

export const useTenant = (): TenantContextType => {
  const context = useContext(TenantContext);
  if (!context) {
    throw new Error('useTenant must be used within a TenantProvider');
  }
  return context;
};
