import { fetchAuthSession } from "@aws-amplify/auth";
import { redirect } from "react-router-dom";
import { Event } from "../types/event";
import { TimesApiResponse } from "../types/timesApiResponse";
import { GetIntegrationsApiResponse } from "../types/integrationsApiResponse";

const BASE_URL = "https://api.devinsights.app"

export async function getTimeToMerge(): Promise<Event[]> {
  return await (
    await loadData("/metrics/time-to-merge")
  ).events;
}

export async function getTimeToOpen(): Promise<Event[]> {
  return (await loadData("/metrics/time-to-open"))
    .events;
}

export async function getTimeToApprove(): Promise<Event[]> {
  return (await loadData("/metrics/time-to-approve"))
    .events;
}

export async function getTimeToFirstReview(): Promise<Event[]> {
  return (
    await loadData("/metrics/time-to-first-review")
  ).events;
}

export async function registerIntegration(payload: {
  [key: string]: string | null;
}) {
  const session = await fetchAuthSession();

  if (!session.tokens || !session.tokens.idToken) {
    console.error(`user is not authenticated redirect to login`);
    return redirect("/login");
  }

  const headers = {
    Authorization: `Bearer ${session.tokens.idToken}`,
  };

  return await fetch(`${BASE_URL}/test/integrations`, {
    method: "PUT",
    headers,
    body: JSON.stringify({
      payload,
    }),
  });
}

export async function deleteIntegration(installationId: string) {
  const session = await fetchAuthSession();

  if (!session.tokens || !session.tokens.idToken) {
    console.error(`user is not authenticated redirect to login`);
    return redirect("/login");
  }

  const headers = {
    Authorization: `Bearer ${session.tokens.idToken}`,
  };

  return await fetch(`${BASE_URL}/test/integrations`, {
    method: "DELETE",
    headers,
    body: JSON.stringify({
      installationId: parseInt(installationId)
    }),
  });
}

export async function getIntegrations(): Promise<GetIntegrationsApiResponse[]> {
  const session = await fetchAuthSession();

  if (!session.tokens || !session.tokens.idToken) {
    console.error(`user is not authenticated redirect to login`);
    throw new Error("User is not authenticated");
  }

  const headers = {
    Authorization: `Bearer ${session.tokens.idToken}`,
  };

  return (await fetch(`${BASE_URL}/test/integrations`, {
    method: "GET",
    headers,
  })).json();
}

export async function getTimes(
  from: number,
  to: number
): Promise<TimesApiResponse> {
  return await loadData(
    `/test/metrics/times?from=${from}&to=${to}`
  );
}

async function loadData(url: string) {
  const session = await fetchAuthSession();

  if (!session.tokens || !session.tokens.idToken) {
    console.error(`user is not authenticated redirect to login`);
    return redirect("/login");
  }

  const headers = {
    Authorization: `Bearer ${session.tokens.idToken}`,
  };
  return (
    await fetch(`${BASE_URL}${url}`, {
      headers,
    })
  ).json();
}

export async function getTenantName(): Promise<string> {
  try {
    const session = await fetchAuthSession();
    const tenantId = session.tokens?.idToken?.payload["custom:tenantId"] as string;
    const data = await loadData(`/tm/tenant/${tenantId}`);
    return data.message.tenant_name;
  } catch (error) {
    console.error(error);
    return 'Unknown Tenant';
  }
}
