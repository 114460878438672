import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import TimeSummary from "../components/TimeSummary";
import PrivateLayout from "../layouts/private/Layout";
import { getTimes } from "../services/api";
import "../App.css";
import { TimesApiResponse } from "../types/timesApiResponse";

function Dashboard() {
  const [times, setTimes] = useState<TimesApiResponse | undefined>();
  const [initialised, setInitialised] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      setInitialised(false);
      const nowInSeconds = Math.floor(new Date().getTime() / 1000);
      const times = await getTimes(nowInSeconds - 604800, nowInSeconds);

      setTimes(times);
      setInitialised(true);
    };
    fetchData();
  }, []);

  return (
    <PrivateLayout>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TimeSummary
            title="Cycle Time"
            stat={{
              current: times?.current.cycleTime ?? 0,
              prev: times?.previous.cycleTime ?? 0,
            }}
            unit="min"
            showTrend={initialised}
          ></TimeSummary>
        </Grid>
        <Grid item xs={3}>
          <TimeSummary
            title="Time to Open"
            stat={{
              current: times?.current.timeToOpen ?? 0,
              prev: times?.previous.timeToOpen ?? 0,
            }}
            unit="min"
            showTrend={initialised}
          ></TimeSummary>
        </Grid>
        <Grid item xs={3}>
          <TimeSummary
            title="Time to First Review"
            stat={{
              current: times?.current.timeToFirstReview ?? 0,
              prev: times?.previous.timeToFirstReview ?? 0,
            }}
            unit="min"
            showTrend={initialised}
          ></TimeSummary>
        </Grid>
        <Grid item xs={3}>
          <TimeSummary
            title="Time to Approve"
            stat={{
              current: times?.current.timeToApprove ?? 0,
              prev: times?.previous.timeToApprove ?? 0,
            }}
            unit="min"
            showTrend={initialised}
          ></TimeSummary>
        </Grid>
        <Grid item xs={3}>
          <TimeSummary
            title="Time to Merge"
            stat={{
              current: times?.current.timeToMerge ?? 0,
              prev: times?.previous.timeToMerge ?? 0,
            }}
            unit="min"
            showTrend={initialised}
          ></TimeSummary>
        </Grid>
      </Grid>
    </PrivateLayout>
  );
}

export default Dashboard;
