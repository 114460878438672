import FavoriteIcon from "@mui/icons-material/Favorite";
import Typography from "@mui/material/Typography";

export const LoveInAuckland = () => {
  return (
    <Typography variant="body1" color="text.primary" align="center">
      {"Made with "}
      <FavoriteIcon color="error"></FavoriteIcon>
      {" in Auckland & Hamburg"}
    </Typography>
  );
};
