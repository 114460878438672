import Link from "@mui/material/Link";
import { Stack } from "@mui/system";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import LogoutButton from "../../components/authentication/LogoutButton";

const Navigation = () => {
  return (
    <Stack component="nav" spacing={2}>
      <Link component={RouterLink} to="/">
        Dashboard
      </Link>
      <Link component={RouterLink} to="/integrations">
        Integrations
      </Link>
      <LogoutButton />
    </Stack>
  );
};

export default Navigation;
