import { Grid } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useSearchParams, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import PrivateLayout from "../layouts/private/Layout";
import "../App.css";
import { registerIntegration } from "../services/api";

function OauthGitHub() {
  const [searchParams] = useSearchParams();
  const [registrationResult, setRegistrationResult] = useState<
    boolean | string
  >();
  const navigate = useNavigate();

  useEffect(() => {
    const payload = {
      installationId: searchParams.get("installation_id"),
      code: searchParams.get("code"),
      setupAction: searchParams.get("setup_action"),
      integration: "github",
    };

    async function getData() {
      try {
        const result = await registerIntegration(payload);
        // ts-ignore
        if (result && result.status === 200) {
          setRegistrationResult(true);
          return navigate("/integrations");
        } else {
          setRegistrationResult(true);
          return navigate("/integrations");
        }
      } catch (error) {
        console.log(error);
        return navigate("/integrations");
      }
    }
    getData();
  }, [registrationResult, searchParams, navigate]);
  return (
    <PrivateLayout>
      <Grid container>
        <LoadingButton loading>Registering</LoadingButton>
      </Grid>
    </PrivateLayout>
  );
}

export default OauthGitHub;
