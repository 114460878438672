import { useTheme } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, { ReactNode } from "react";
import { Footer } from "../../components/Footer";
import Navigation from "./Navigation";
import Header from "../../components/Header";

function PrivateLayout({ children }: { children: ReactNode }) {
  const theme = useTheme();

  return (
    <Grid
      container
      spacing={2}
      sx={{
        paddingTop: theme.spacing(4),
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        minHeight: "100vh",
        flexDirection: "column",
      }}
    >
      <Grid xs={12} sx={{ paddingBottom: theme.spacing(3) }}>
        <Header />
      </Grid>
      <Grid container xs={12} spacing={7} marginTop={3}>
        <Grid xs={2}>
          <Navigation />
        </Grid>
        <Grid xs={10}>
          <main>{children}</main>
        </Grid>
      </Grid>
      <Grid sx={{ marginTop: "auto" }} xs={12}>
        <Footer />
      </Grid>
    </Grid>
  );
}

export default PrivateLayout;
