import Grid from "@mui/material/Unstable_Grid2";
import { Copyright } from "./Copyright";
import { LoveInAuckland } from "./LoveInAuckland";

export const Footer = () => {
  return (
    <footer>
      <Grid spacing={1}>
        <Grid>
          <LoveInAuckland />
        </Grid>
        <Grid>
          <Copyright />
        </Grid>
      </Grid>
    </footer>
  );
};
