import React from 'react';
import { Typography } from '@mui/material';
import { useTenant } from '../contexts/TenantContext';

const Header: React.FC = () => {
    const { tenantName } = useTenant();
 

  return (
    <header>
      <Typography variant="h5">DevInsights for <b>{tenantName}</b></Typography>
    </header>
  );
};

export default Header;
